import { getPlat } from "@/utils/auth"
import { apiClient } from "@/utils/request"
import { API } from "./typings"

// 提交问题
export const submitQuestion = (data: any, Id: string, sessionId: string) => {
  // console.log(sessionId, "session_id")
  if (getPlat() === "tyj") {
    return apiClient.post<API.ApiResponse<any>>("/chat/personsStream", {
      data: { messages: data, message_id: Id, session_id: sessionId },
    })
  } else if (getPlat() === "xyt") {
    return apiClient.post<API.ApiResponse<any>>("/chat/insStream", {
      data: { messages: data, message_id: Id, session_id: sessionId },
    })
  } else if (getPlat() === "ghmp" || getPlat() === "zbmp") {
    return apiClient.post<API.ApiResponse<any>>("/chat/mpStream", {
      data: { messages: data, message_id: Id, session_id: sessionId },
    })
  } else {
    return apiClient.post<API.ApiResponse<any>>("/chat/stream", {
      data: { messages: data, message_id: Id, session_id: sessionId },
    })
  }
}
// 获取消息

export const chatStream = (Id: string) => {
  if (getPlat() === "tyj") {
    return apiClient.put<API.ApiResponse<any>>("/chat/personsStream", {
      data: { message_id: Id },
    })
  } else if (getPlat() === "xyt") {
    return apiClient.put<API.ApiResponse<any>>("/chat/insStream", {
      data: { message_id: Id },
    })
  } else if (getPlat() === "ghmp" || getPlat() === "zbmp") {
    return apiClient.put<API.ApiResponse<any>>("/chat/mpStream", {
      data: { message_id: Id },
    })
  } else {
    return apiClient.put<API.ApiResponse<any>>("/chat/stream", {
      data: { message_id: Id },
    })
  }
}

//获取可发送消息数
export const chatCountNumber = () => {
  if (getPlat() === "ghmp" || getPlat() === "zbmp") {
    return apiClient.get<API.ApiResponse<any>>("/chat/mpStream", {})
  } else {
    return apiClient.get<API.ApiResponse<any>>("/chat/personsStream", {})
  }
}

//获取公众号OpenId
export const chatWxOpenId = (data: any) => {
  return apiClient.post<API.ApiResponse<any>>("/mp/login", { data })
}

//获取分享签名
export const chatWxSignature = () => {
  return apiClient.post<API.ApiResponse<any>>("/mp/signature", {})
}
