const wxCodeUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7e673fb395c92bc1&redirect_uri=https%3A%2F%2Fghchat.guohualinks.com%2Fauth&response_type=code&scope=snsapi_base&state=ghmp#wechat_redirect"

const zbwxCodeUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0e9f6b110ba70784&redirect_uri=http%3A%2F%2Fgpt.zbintel.net%2Fauth&response_type=code&scope=snsapi_base&state=zbmp#wechat_redirect"
const AuthWx = () => {
  window.location.href = wxCodeUrl;

  return <div></div>
}

export default AuthWx
