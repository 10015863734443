import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider } from '@/context/auth'
import Auth from '@/pages/Auth'
import Home from '@/pages/Home'
import ZBHome from '@/pages/ZBHome'
import AuthWx from './pages/AuthWx'
import GhShare from './pages/GhShare'
// import GuoHuaChat from './pages/GuohuaChat'

const AuthenticatedApp = () => {
	return (
		// basename='/happygame'
		<BrowserRouter >
			<Routes>
				<Route path='/authwx' element={<AuthWx />} />
				<Route path='/auth' element={<Auth />} />

				{/* <Route
					path='/'
					element={
						<ZBHome />
					}
				/> */}
				<Route
					path='/'
					element={
						<Home />
					}
				/>

				<Route
					path='/GhShare'
					element={
						<GhShare></GhShare>
					}>
				</Route>

			</Routes>
		</BrowserRouter>
	)
}

export default AuthenticatedApp
