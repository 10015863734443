import { chatWxOpenId } from '@/services/api'
import { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { getToken, setPlat, setToken } from '../../utils/auth'

const Auth = () => {
	const [search] = useSearchParams()
	const navigate = useNavigate()

	const handlerCodeOpenId = async (wxcode: string) => {
		const { code, data } = await chatWxOpenId({ code: wxcode })
		if (code === 0) {
			setToken(data.openid);
		}
		if (getToken()) {
			navigate("/")
		}
		
	}
	useEffect(() => {
		const token = search.get('token')
		const plat = search.get('plat')
		const state = search.get('state')
		const wxcode = search.get('code')

		if (!wxcode && token) {
			setPlat(plat || '')
			setToken(token || '')
			navigate('/')
		} else if (wxcode) {
			setPlat(state || '')
			handlerCodeOpenId(wxcode)
		}
	}, [search])

	return <div></div>
}

export default Auth
