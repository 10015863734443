export const AuthTokenKey = "__chat_token__"
export const AuthPlat = "__chat_plat__"
export const AuthOpenId = "__chat_OpenId__"

export const getToken = () => window.localStorage.getItem(AuthTokenKey)

export const setToken = (token: string) =>
  window.localStorage.setItem(AuthTokenKey, token)

export const getPlat = () => window.localStorage.getItem(AuthPlat)

export const setPlat = (plat: string) =>
  window.localStorage.setItem(AuthPlat, plat)

export const getOpenId = () => window.localStorage.getItem(AuthOpenId)

export const setOpenId = (openid: string) =>
  window.localStorage.setItem(AuthPlat, openid)
