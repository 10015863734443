import { Image } from "antd-mobile";



const GhShare = () => {
  
  const shareUrl =  "https://guohua-institution.oss-cn-beijing.aliyuncs.com/ghchatgpt.png"

  return (
    <div>
      <Image src={shareUrl}></Image>
    </div>
  )
}

export default GhShare;