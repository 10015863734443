export const BackSrc = () => {
    return require("@/assets/si-glyph-circle-backward.png")
}
export const questionBg = (tmp_code: string) => {
  if (tmp_code) {
    return require("@/assets/" + tmp_code + "/questionBg.png")
  }
}
export const rankBg = (tmp_code: string) => {
  if (tmp_code) {
    return require("@/assets/" + tmp_code + "/rank_bg.png")
  }
}
export const questionTitle = (tmp_code: string) => {
  if (tmp_code) {
    return require("@/assets/" + tmp_code + "/questionTitle.png")
  }
}
export const rankTitle = (tmp_code: string) => {
  if (tmp_code) {
    return require("@/assets/" + tmp_code + "/rankTitle.png")
  }
}

export const finishQuestionColor = (tmp_code: string) => {
  if (tmp_code === "happy_learn2") {
    return "#FFFFFF"
  } else {
    return "#FFBD3A"
  }
}
