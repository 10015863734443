import styles from './index.module.less'
import React, { useEffect, useRef, useState } from 'react'
import { chatCountNumber, chatStream, submitQuestion } from '@/services/api';
import { Button, DotLoading, Form, Input, TextAreaRef, Toast } from 'antd-mobile';
import { FormInstance } from 'rc-field-form';
import { List } from 'antd-mobile/es/components/list/list';
import { ListItem } from 'antd-mobile/es/components/list/list-item';
import { v4 as uuidv4 } from "uuid"
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { getPlat } from '@/utils/auth';
import { BackSrc } from '@/utils/themStyle';
import { useShareSign } from './hooks';


type ChatModal = {
	role: string;
	content: string;
	// message_id: string;
}
const initialMessages = [
	{
		type: 'text',
		content: '您好，欢迎使用国华ChatGPT~ ',
		user: { avatar: 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/ic_chatgpt_active.svg' },
	},
];
const initialMpMessages = [
	{
		type: 'text',
		content: '您好，欢迎使用国华助残服务基于ChatGPT打造的智能咨询服务，您可以免费咨询法律和心理等问题！ ',
		user: { avatar: 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/ic_chatgpt_active.svg' },
	},
];
const initTyjMessages = [
	{
		type: 'text',
		content: '您好，欢迎使用同益加基于ChatGPT打造的智能咨询服务，您可以免费咨询法律和心理等问题！ ',
		user: { avatar: 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/ic_chatgpt_active.svg' },
	},
];

const lastMsgMessages = [
	// {
	// 	type: 'image',
	// 	content: {
	// 		picUrl: 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/ghchatgpt.png',
	// 	},
	// 	user: { avatar: 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/ic_chatgpt_active.svg' },
	// },
	{
		type: 'text',
		content: '分享朋友圈，解锁更多使用权限，继续你的数智探索之旅～',
		user: { avatar: 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/ic_chatgpt_active.svg' },
	}
]



const uu_id = uuidv4()
// console.log(uu_id)

const Home = () => {

	const { data: shareData } = useShareSign();

	const formRef = React.createRef<FormInstance>()

	const [messageContent, setMessageContent] = useState<ChatModal[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isNone, setIsNone] = useState<boolean>(false);
	const [isButton, setIsButton] = useState<boolean>(false);

	const [sendNumber, setSendNumber] = useState<string>('发送');

	// const textArea = useRef<TextAreaRef>(null);

	//当前会话开始时间
	const commentTime = useRef<number>(0);


	const [form] = Form.useForm()

	const platChat = getPlat() === 'tyj';
	const mpChat = getPlat() === 'ghmp';


	// 发送次数
	const sendMessageCount = async () => {
		setIsButton(true)
		const { data, code } = await chatCountNumber();
		if (code === 0) {
			if (Number(data.message_max_num) === -1) {
				setSendNumber('发送')
			} else {
				const numberCount = Number(data.message_max_num) - Number(data.message_num)
				if (numberCount > 0) {
					setSendNumber('发送(' + numberCount.toString() + ')')
				} else {
					setSendNumber('今日已达上限')
					setIsNone(false)
				}
			}
		}
		setIsButton(false)
	}

	// 得到返回消息
	const messageStream = async (msg: any, Id: string) => {
		// const lastMsgLength = msg.slce(-1).content.length;
		const { code, data, message } = await chatStream(Id)
		// console.log(data)
		if (code === 0) {

			if (!data.is_ended) {

				//超时处理
				const timesStemtamp = new Date().getTime();
				if (commentTime.current > 0 && (timesStemtamp - commentTime.current) / 1000 > 180 && (!data.message)) {
					setIsNone(false)
					setIsLoading(false)
					Toast.show({
						content: '请求异常，请重新发送',
					})
					return
				}

				setTimeout(() => {
					messageStream(msg, Id)
				}, 500)
				setIsNone(true)
			} else {
				if (platChat || mpChat) {
					sendMessageCount()
				}
				setIsNone(false)
			}
			msg.forEach((item: any, index: any) => {
				if (msg.length === index + 1 && item.role === "assistant" && data.message_id === Id) {
					item.content += data.message.trim().replace(item.content, '')
				}
			});
			setMessageContent([...msg])

			window.scrollTo(0, document.body.scrollHeight);
		} else {
			Toast.show({
				content: message,
			})
		}
		setIsLoading(false)

	}


	// 提交问题
	const onSubmit = async () => {

		const values = form.getFieldsValue()

		if (!values) {
			Toast.show({
				content: '请输入内容',
			})
			return
		}
		const id = uuidv4()
		// console.log(id)
		const msg = [...messageContent]
		// console.log(values)
		if (msg.length > 0) {
			msg.push({ role: "user", content: values.messageKey })
		} else {
			msg.push({ role: "system", content: values.messageKey })
		}
		console.log(values.messageKey);
		console.log(msg);

		setIsNone(true)
		setMessageContent(msg)

		setIsLoading(true)
		formRef.current?.setFieldsValue({ messageKey: '' })



		setTimeout(async () => {
			//获取当前时间
			commentTime.current = new Date().getTime();
			const { code } = await submitQuestion(msg, id, uu_id)

			//获取消息数量放到  消息返回ture时调用
			console.log(code);

		}, 100)

		setTimeout(() => {
			msg.push({ role: "assistant", content: '' })
			messageStream(msg, id);
		}, 200)

	}


	// const [rows, setRows] = useState(1);
	// const handleKeyPressRef = useRef((e: any) => { });
	// const handleInput = useCallback(() => {
	// 	if (textArea.current?.nativeElement) {
	// 		const textarea = textArea.current?.nativeElement;
	// 		setTimeout(() => {
	// 			textarea.rows = 1;
	// 			textarea.rows = Math.ceil(textarea.scrollHeight / 26);
	// 		}, 0);
	// 	}

	// }, []);

	const handlerDownLoadPic = () => {
		// let link = document.createElement('a');
		// link.style.display = 'none';
		// document.body.appendChild(link);
		// // 设置链接元素的下载属性
		// link.href = shareUrl;
		// link.download = shareUrl;
		// // 模拟点击链接元素以触发下载
		// link.click();
		// // 移除链接元素
		// document.body.removeChild(link);
		//@ts-ignore
		wx.ready(function () {
			//@ts-ignore
			// wx.onMenuShareAppMessage({
			// 	title: '标题', // 分享标题
			// 	desc: '分享描述', // 分享描述
			// 	link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致,2为锦囊
			// 	imgUrl: 'https://share.png', // 分享图标
			// 	type: '', // 分享类型,music、video或link，不填默认为link
			// 	success: function (res: any) {
			// 		// 用户点击了分享后执行的回调函数
			// 	}
			// })
			//@ts-ignore
			wx.onMenuShareTimeline({
				title: '国华chatGPT', // 分享标题
				desc: '欢迎使用国华助残服务基于ChatGPT打造的智能咨询服务，您可以免费咨询法律和心理等问题！', // 分享描述
				link: 'https://ghchat.guohualinks.com/GhShare', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/logo.2222.png', // 分享图标
				type: 'link', // 分享类型,music、video或link，不填默认为link
				dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
				success: function (res: any) {
					// 用户点击了分享后执行的回调函数
					Toast.show({
						content: '分享成功'
					})
					sendMessageCount();
				}
			})
		})
	}

	const wxConfig = (res: any) => {
		//@ts-ignore
		wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
			appId: 'wx7e673fb395c92bc1', // 必填，公众号的唯一标识
			timestamp: res.timestamp, // 必填，生成签名的时间戳
			nonceStr: res.noncestr, // 必填，生成签名的随机串
			signature: res.signature, // 必填，签名
			jsApiList: ['onMenuShareAppMessage', 'onMenuShareTimeline'] // 必填，需要使用的JS接口列表
		})

	}

	useEffect(() => {
		if (platChat || mpChat) {
			document.title = "智能咨询";
			sendMessageCount()
		} else {
			document.title = "国华ChatGPT";
		}


		// handleKeyPressRef.current = (event: any) => {
		// 	console.log(isNone)
		// 	if (event.keyCode === 13 && !event.shiftKey && !isNone) {
		// 		event.preventDefault();
		// 		// 执行你的操作
		// 		onSubmit();
		// 	}
		// };
		// const textArea = document.querySelector('textarea');
		// textArea?.addEventListener('keydown', handleKeyPressRef.current);
		// return () => {
		// 	textArea?.removeEventListener('keydown', handleKeyPressRef.current);
		// };

		if (shareData?.data) {
			console.log(shareData.data)
			wxConfig(shareData.data)
		}

	}, [shareData])





	const copyToClipboard = (text: string) => {
		if (text) {
			copy(text);
			Toast.show({
				content: '文本已复制到剪贴板',
			})
		}
	}

	return (
		<div className={styles.home}>



			<div style={{
				paddingBottom: '80px',
				background: '#fff'
			}}>
				{/* <VisibilitySensor> */}
				<List>

					{(platChat ? initTyjMessages : mpChat ? initialMpMessages : initialMessages).map((item: any) => {
						return (<ListItem key={item.type} >

							<div className={styles.user_content}>
								{item.content ?
									(<>
										<div className={styles.user_head}></div>
										{item.type === 'text' ? <div className={styles.user_message}>{item.content}</div> : <img className={styles.user_image} src={item.content.picUrl} ></img>}
									</>
									) : <></>}

							</div>
						</ListItem>)
					})}
					{messageContent.map((item: any, index: any) => {
						return (
							<ListItem key={index} >
								{
									item.role === 'system' || item.role === 'user' ?
										(<div className={styles.chat_content}>  <div className={styles.chat_head}></div>
											<div className={styles.user_desc}>{item.content}</div></div>)
										: (<div className={styles.user_content}>
											<div className={styles.user_head}></div>
											<div className={classNames(styles.user_message, { [styles.user_message_show]: isNone && index + 1 === messageContent.length })} onClick={() => copyToClipboard(item.content)}>{item.content}</div>
										</div>)
								}
							</ListItem>
							// onClick={() => copyToClipboard(item.content)}
							// dangerouslySetInnerHTML={{ __html: item.content.replace(/\n/g, '<br />') }} 
						)
					})}

					{/* {sendNumber === '今日已达上限'}
					{ mpChat} */}

					{sendNumber === '今日已达上限' && mpChat && lastMsgMessages.map((item: any) => {
						return (<ListItem key={item.type} >

							<div className={styles.user_content}>
								{item.content ?
									(<>
										<div className={styles.user_head}></div>
										{item.type === 'text' ? <div style={{ width: 'auto', maxWidth: '85%' }}>
											<div className={styles.user_message}>{item.content}</div>
											<Button style={{
												marginTop: '5px'
											}} color='success' onClick={handlerDownLoadPic}>立即分享</Button>
										</div> : <img className={styles.user_image} src={item.content.picUrl} ></img>

										}
									</>
									) : <></>}

							</div>
						</ListItem>)
					})}

				</List>
				{/* </VisibilitySensor> */}
				{isLoading && <span style={{ fontSize: 24, marginLeft: '10px' }}><DotLoading color='primary' /></span>}




			</div>

			<div className={styles.input_location}>
				<Form layout='vertical' form={form} ref={formRef}>
					<div className={styles.input_element} >

						{platChat && (<div className={styles.back_icon} >
							<img alt='back' className={styles.back_img} src={BackSrc()} onClick={() => {
								//@ts-ignore
								wx.miniProgram.navigateBack({
									delta: 1
								})
							}}></img>

						</div>)}
						{/* rules={[{ required: true, message: '内容不能为空' }]} */}
						<Form.Item name='messageKey'  >
							{/* <TextArea placeholder='请输入内容' style={{ padding: '10px 0', }} rows={1} onChange={handleInput} ref={textArea}
							/> */}
							<Input placeholder='请输入内容' onEnterPress={sendNumber === '今日已达上限' ? undefined : onSubmit} />
						</Form.Item>
						{/* disabled={isNone} */}
						<Button color='success' disabled={sendNumber === '今日已达上限'} onClick={onSubmit} style={{
							whiteSpace: 'nowrap'
							, borderRadius: '10px', marginBottom: '5px'
						}} loading={(isButton || isNone) && sendNumber !== '今日已达上限'} >{sendNumber}
							{/* 发送{sendNumber ? '(' + sendNumber + ')' : ''} */}
						</Button>
					</div>
				</Form>
			</div >




		</div >
	)
}

export default Home
